@tailwind base;
@tailwind components;
@tailwind utilities;


.dropdown:hover .dropdown-menu {
  display: block;
}

#hero1 {
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-color: #fff;
  /* background-attachment: fixed; */
}

#heroGlobal {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-color: #fff;
  /* background-attachment: fixed; */
}

#heroGlobal2 {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 60px;
  padding-bottom: 0px;
  /* background-attachment: fixed; */
}


#utama {
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  /* background-color: #fff; */
  background-attachment: fixed;
}

.-ml-full{
  margin-left: -100%;
}

.-mr-full{
  margin-right: -100%;
}

#kontributor .slick-slide {
	margin: 0 16px;
}

@media only screen and (max-width: 600px) {
  #hero1 {
    background-size:cover;
    background-position: center center;
    
  }

  #utama {
    background-size:cover;
    background-position:left;
    background-repeat: no-repeat;
    
    /* background-color: #fff; */
    background-attachment:initial;
  }
  #kontributor .slick-slide {
    margin: 0 0px;
  }
}

